<template>
    <v-footer fixed dark padless app class="no-print">
        <v-row justify="center" no-gutters>
            <v-col cols="12" class="text-caption pa-0 dark font-weight-light" style="text-align: center">
                    &copy; {{ currentYear }} <a :href="mainWebsiteURL" style="color: #dddddd;"><strong>Cryptium</strong></a>. All rights reserved.
            </v-col>
        </v-row>
    </v-footer>
</template>
<style>
@media print {
    .no-print {
        display: none !important;
    }
}
</style>
<script>
export default {
    computed: {
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://cryptium.com';
        },
        currentYear() {
            const now = new Date();
            return now.getFullYear();
        },
    },
};
</script>
