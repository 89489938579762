/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Service {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async getInfo() {
        return getJson(`${this.url}`);
    }

    async getVersion() {
        return getJson(`${this.url}/service/state/version`, null, { requestHeaders: this.requestHeaders });
    }

    async getSettingsList() {
        return getJson(`${this.url}/service/state/config`, null, { requestHeaders: this.requestHeaders });
    }

    async editSetting(request) {
        return postJsonAcceptJson(`${this.url}/service/edit/config`, request, null, { requestHeaders: this.requestHeaders });
    }

    async getAccountList(request) {
        return getJson(`${this.url}/service/search/account`, request, { requestHeaders: this.requestHeaders });
    }

    async getDomainList(request) {
        return getJson(`${this.url}/service/search/domain`, request, { requestHeaders: this.requestHeaders });
    }

    async deleteAccount(request) {
        return postJsonAcceptJson(`${this.url}/service/account/delete`, request, null, { requestHeaders: this.requestHeaders });
    }

    async getTinydnsConfig() {
        return getJson(`${this.url}/service/state/tinydns`, null, { requestHeaders: this.requestHeaders });
    }

    async getC2Config() {
        return getJson(`${this.url}/service/state/c2`, null, { requestHeaders: this.requestHeaders });
    }

    async getLetsencryptConfig() {
        return getJson(`${this.url}/service/state/letsencrypt`, null, { requestHeaders: this.requestHeaders });
    }

    async setupLetsencryptConfig(request) {
        return postJsonAcceptJson(`${this.url}/service/edit/letsencrypt`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class DynamicSharedDomain {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/service/check/dynamic-shared-domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/service/create/dynamic-shared-domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(request) {
        return postJsonAcceptJson(`${this.url}/service/delete/dynamic-shared-domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/service/edit/dynamic-shared-domain`, request, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/service/state/dynamic-shared-domain`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/service/search/dynamic-shared-domain`, query, { requestHeaders: this.requestHeaders });
    }
}

class ServiceTlsCertificate {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(id) {
        return getJson(`${this.url}/service/state/tls-certificate`, { id }, { requestHeaders: this.requestHeaders });
    }

    async deploy(request) {
        return postJsonAcceptJson(`${this.url}/service/rpc/deploy-tls-certificate`, request, null, { requestHeaders: this.requestHeaders });
    }

    async renew(request) {
        return postJsonAcceptJson(`${this.url}/service/rpc/renew-tls-certificate`, request, null, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/service/search/tls-certificate`, query, { requestHeaders: this.requestHeaders });
    }
}

class Authn {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}/authn/session`, request, { requestHeaders: this.requestHeaders });
    }

    async deleteSession(request) {
        return postJsonAcceptJson(`${this.url}/authn/session/delete`, request, null, { requestHeaders: this.requestHeaders });
    }

    async reservePseudonym(request) {
        return postJsonAcceptJson(`${this.url}/authn/pseudonym/reserve`, request, null, { requestHeaders: this.requestHeaders });
    }

    async signup(request) {
        return postJsonAcceptJson(`${this.url}/authn/signup`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async logout(request = {}) {
        return postJsonAcceptJson(`${this.url}/authn/logout`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class CurrentUser {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: change all refereces to user(id).get()
    // async get() {
    //     return getJson(`${this.url}/state`, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all references to user(id).edit(request)
    // async edit(request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/current-user/edit`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all referfencces to user(id).delete()
    // async delete() {
    //     return postJsonAcceptJson(`${this.url}/current-user/delete`, null, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all refernces to user(id).getAccountList()
    // async getAccountList(request) {
    //     return getJson(`${this.url}/current-user/account-list`, request, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all references to user(id).createAccount(request)
    // async createAccount(request) {
    //     return postJsonAcceptJson(`${this.url}/current-user/create/account`, request, null, { requestHeaders: this.requestHeaders });
    // }
}

class Interaction {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/interaction/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/interaction`, { id }, { requestHeaders: this.requestHeaders });
    }

    async resume(token) {
        return postJsonAcceptJson(`${this.url}/interaction/resume`, { token }, null, { requestHeaders: this.requestHeaders });
    }

    async edit(id, message) {
        return postJsonAcceptJson(`${this.url}/interaction/edit`, message, { id }, { requestHeaders: this.requestHeaders });
    }

    async getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return getJson(`${this.url}/interaction/token/status`, { tokenId }, { requestHeaders: this.requestHeaders });
    }
}

class Webauthz {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // for authorization server
    async getAccessPrompt(id) {
        return getJson(`${this.url}/webauthz/prompt`, { id }, { requestHeaders: this.requestHeaders });
    }

    // for authorization server
    async grantAccess(id, permit = {}) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'grant', permit }, { requestHeaders: this.requestHeaders });
    }

    // for authorization server
    async denyAccess(id) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'deny' }, { requestHeaders: this.requestHeaders });
    }

    // for client application
    async finishWebauthzRequest(query, request) {
        return postJsonAcceptJson(`${this.url}/webauthz/grant`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.authn = new Authn(context);
        this.currentUser = new CurrentUser(context);
        this.dynamicSharedDomain = new DynamicSharedDomain(context);
        this.interaction = new Interaction(context);
        this.service = new Service(context);
        this.serviceTlsCertificate = new ServiceTlsCertificate(context);
        this.webauthz = new Webauthz(context);
    }
}

export default BrowserClient;
export {
    Authn,
    CurrentUser,
    DynamicSharedDomain,
    Interaction,
    Service,
    ServiceTlsCertificate,
    Webauthz,
};
