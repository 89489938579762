<template>
    <v-avatar :size=size>
        <v-img v-if="useCustomImage" :src="iconData"></v-img>
        <font-awesome-icon v-if="useDefaultIcon" icon="user-circle" fixed-width size="2x"></font-awesome-icon>
    </v-avatar>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
            default() {
                return {
                    icon: '', // base64 icon data
                };
            },
        },
        size: {
            type: Number,
            default: 500,
        },
    },

    computed: {
        iconData() {
            if (this.attr.icon) {
                return `data:image/png;base64,${this.attr.icon}`;
            }
            return '';
        },
        useCustomImage() {
            // only if a custom icon is available
            if (this.attr.icon) {
                return true;
            }
            return false;
        },
        useDefaultIcon() {
            // not if useCustomImage
            if (this.attr.icon) {
                return false;
            }
            // default
            return true;
        },
    },

};
</script>
