import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/FrontPage.vue'),
        },
        {
            path: '/pricing',
            name: 'pricing',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingPage.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/SignupActivity.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/UserDashboard.vue'),
        },
        {
            path: '/settings',
            name: 'user-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "settings" */ '../views/user/UserSettings.vue'),
        },
        {
            path: '/account',
            name: 'user-account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-dashboard" */ '../views/user/AccountDashboard.vue'),
        },
        {
            path: '/profile',
            name: 'user-profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/user/UserProfile.vue'),
        },
        {
            path: '/profile/xentri-settings',
            name: 'profile-settings-authn-xentri',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-xentri" */ '../views/user/NavigateToXentriSettings.vue'),
        },
        {
            path: '/session',
            name: 'session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "session" */ '../views/Session.vue'),
        },
        {
            path: '/profile/delete',
            name: 'user-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-delete" */ '../views/user/DeleteProfile.vue'),
        },
        {
            path: '/user/connect-account',
            name: 'user-connect-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-connect-account" */ '../views/user/ConnectAccount.vue'),
        },
        {
            path: '/user/connect-xentri',
            name: 'user-connect-xentri',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-connect-xentri" */ '../views/user/ConnectXentri.vue'),
        },
        {
            path: '/user/check',
            name: 'user-check',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-check" */ '../views/user/ProfileCheck.vue'),
        },
        {
            path: '/user/openvpn-software',
            name: 'user-openvpn-software',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-openvpn-software" */ '../views/user/OpenVpnSoftware.vue'),
        },
        {
            path: '/user/openvpn-config',
            name: 'user-openvpn-config',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-openvpn-config" */ '../views/user/OpenVpnConfig.vue'),
        },
        {
            path: '/user/openvpn-password',
            name: 'user-openvpn-password',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-openvpn-password" */ '../views/user/OpenVpnPassword.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
